<template>
	<section class="container container-l mx-auto">
		<hero
			text="Is simply dummy text of the printing and typesetting industry. "
		>
			<img alt="Vue logo" src="@/assets/images/hero.svg" />
		</hero>
		<div class="flex flex-col container mx-auto">
			<div
				v-for="(card, index) in data"
				:key="card.id"
				class="job flex flex-wrap xs:flex-nowrap container mx-auto my-0 xs:my-6 px-4 xs:px-0"
			>
				<!-- <div class="flex container mx-auto my-6 job"> -->
				<div
					class="w-6/12 xs:w-1/4 transform translate-y-10 xs:translate-y-0 translate-x-0 xs:translate-x-1/2 mx-auto xs:mx-0"
					:class="{
						'transform translate-y-10 xs:translate-y-0 translate-x-0 xs:translate-x-1/2':
							$i18n.locale === 'ar' && index % 2 == !0,

						'transform translate-y-10 xs:translate-y-0 translate-x-0 xs:-translate-x-1/2':
							$i18n.locale !== 'ar' && index % 2 == !0,

						'xs:-translate-x-1/2': $i18n.locale === 'ar' && index % 2 === 0
					}"
				>
					<img alt="Vue logo" src="@/assets/images/jop.svg" />
				</div>
				<div
					class="w-full xs:w-4/5 p-3 flex mx-auto rounded-3xl job-card px-4 xs:px-0"
				>
					<div
						class="mt-6 w-full xs:w-3/4 ms-auto"
						:class="{
							'mr-auto ': index % 2 == !0
						}"
					>
						<h2 class="text-2xl sm:text-4xl mt-4 xs:mt-0">
							{{ card.function.en }}
						</h2>
						<p
							class="w-full xs:w-3/4 my-6"
							:class="open.includes(card.id) ? '' : 'custom-lines-p'"
						>
							{{ card.summary.en }}
						</p>
						<div v-if="open.includes(card.id)" class="container">
							<div class="flex container mx-auto">
								<div class="">
									<div class="mt-6 flex flex-col">
										<div class="flex flex-wrap xl:flex-nowrap mb-3">
											<span class="w-3/5 xs:w-1/2 sm:w-1/3 lg:w-1/4 flex"
												>{{ $t("common.location") }}
											</span>
											:
											<span class="mx-2">location</span>
										</div>
										<div class="flex flex-wrap xl:flex-nowrap mb-3">
											<span class="w-3/5 xs:w-1/2 sm:w-1/3 lg:w-1/4 flex">
												{{ $t("common.level") }}
											</span>
											:
											<span class="mx-2">{{ card.exp_level.en }}</span>
										</div>
										<div class="flex flex-wrap xl:flex-nowrap mb-3">
											<span class="w-3/5 xs:w-1/2 sm:w-1/3 lg:w-1/4 flex">
												{{ $t("common.experience") }}
											</span>
											:
											<span class="mx-2">{{ card.exp_years }} </span>
										</div>
										<div class="flex flex-wrap xl:flex-nowrap mb-3">
											<span class="w-3/5 xs:w-1/2 sm:w-1/3 lg:w-1/4 flex">
												{{ $t("common.schedule") }}
											</span>
											:
											<span class="mx-2">{{ card.schedule.en }}</span>
										</div>

										<div class="flex flex-wrap xl:flex-nowrap mb-3">
											<span
												class="w-3/5 xs:w-1/2 sm:w-1/3 lg:w-1/4 flex-shrink-0 flex-wrap"
											>
												{{ $t("common.responsibilities") }}
											</span>
											:
											<span class="mx-2 flex flex-grow">
												{{ card.responsibilities.en[0] }}
											</span>
										</div>
										<div class="flex flex-wrap xl:flex-nowrap mb-3">
											<span
												class="w-3/5 xs:w-1/2 sm:w-1/3 lg:w-1/4 flex-shrink-0 flex-wrap"
											>
												{{ $t("common.qualifications") }}
											</span>
											:
											<span class="mx-2 flex flex-grow">
												{{ card.qualifications.en[0] }}
											</span>
										</div>
									</div>
								</div>
							</div>
							<!-- <button
								class="text-secondary-600 mt-6 bg-primary-600 py-3 rounded-2xl px-11 flex mx-auto"
								@click="show()"
							>
								apply to job
							</button> -->
						</div>
						<div class="text-primary-600 mt-20 flex flex-wrap items-center">
							<!-- @click="Opened(card.id)" -->
							<button
								v-if="open.includes(card.id)"
								class="text-secondary-600 bg-primary-600 py-3 rounded-2xl px-11 flex whitespace-nowrap"
								@click="show()"
							>
								{{ $t("labels.applyToJob") }}
							</button>
							<div
								v-if="open.includes(card.id)"
								@click="Opened(card.id)"
								class="text-secondary-600 px-12 mt-4 sm:mt-0 cursor-pointer"
							>
								{{ "close" }}
							</div>
							<div
								v-else
								@click="Opened(card.id)"
								class="text-xl sm:text-2xl cursor-pointer"
							>
								{{ "See  more" }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- </div> -->

			<p class="w-10/12 sm:w-1/3 flex mx-auto mt-11">
				{{ $t("misc.sendCV") }}
			</p>
			<div class="flex flex-col mx-auto text-center">
				<button
					class="text-secondary-600 mt-6 bg-primary-600 py-3 rounded-2xl px-11 flex mx-auto"
				>
					{{ $t("labels.applyNow") }}
				</button>

				<template>
					<modal
						name="my-first-modal"
						:scrollable="true"
						:height="'auto'"
						class="py-10"
						:width="500"
					>
						<forms
							:JobTitle="false"
							Job="Job Title"
							:UploadFile="false"
							Upload="Upload your CV"
							message="Leave message (Optional)"
							title="Apply to job  "
						/>
					</modal>
				</template>
			</div>
		</div>
	</section>
</template>
<script>
import Hero from "@/components/Hero.vue";
import Forms from "@/components/Forms.vue";

export default {
	components: {
		Hero,
		Forms
	},

	methods: {
		show() {
			this.showin = true;
			this.$modal.show("my-first-modal");
		},
		hide() {
			this.$modal.hide("my-first-modal");
		},
		Opened(id) {
			if (this.open.includes(id)) {
				const index = this.open.findIndex(item => item === id);
				this.open.splice(index, 1);
			} else {
				this.open.push(id);
			}
		}
	},

	data() {
		return {
			open: [],
			showin: false,
			Seemore: "See more",

			data: [
				{
					index: 18,
					id: 1,
					title: {
						en: "Dicta voluptatem eos dolor consequuntur eaque."
					},
					exp_level: {
						en: "Mid-Level"
					},
					exp_years: 2,
					location: {
						en: "Benjaminshire"
					},
					schedule: {
						en: "Full time"
					},
					function: {
						en: "Web Development"
					},
					summary: {
						en:
							"Inventore blanditiis distinctio et molestias. Sint eos et autem quia ipsum reprehenderit tempora quibusdam. Quasi illum asperiores inventore nulla culpa."
					},
					responsibilities: {
						en: [
							"Adipisci sint voluptas facilis. Voluptatum et quisquam occaecati ut ipsa officia fuga consectetur. Qui omnis saepe consequuntur nostrum earum cum. Quia facilis illum ut non quis.",
							"Corrupti unde id perferendis accusantium maiores. Est dolor vel est corrupti voluptatem sapiente doloribus. Animi voluptatem beatae quisquam aliquam saepe.",
							"Saepe minus adipisci pariatur ipsum. Voluptatem et hic consequatur nisi qui esse. Ut quod sapiente laudantium quam. Sed qui rerum voluptatum."
						]
					},
					qualifications: {
						en: [
							"Vel quia iusto eaque aspernatur ut enim. Ea repudiandae ex et animi rerum ea vero. Itaque impedit quia est optio dolorem dolorem quia sit. Consectetur est reprehenderit dolor ipsum laboriosam molestiae. Dolor praesentium voluptatem dolores numquam iusto veniam.",
							"Magni vero rerum et facere veniam minima. Laborum nihil fugit quaerat praesentium eum possimus nulla. Adipisci velit ea distinctio eum non. Recusandae omnis culpa dolor id.",
							"Sed fuga dicta ut ut totam consequuntur. Debitis quia aliquid ut quia. Assumenda non neque accusantium nemo voluptas sed eos. Suscipit aut perspiciatis temporibus nihil architecto facere sed saepe."
						]
					},
					is_remote: true
				},
				{
					index: 6,
					id: 2,
					title: {
						en:
							"Labore laboriosam aut eveniet doloremque rerum temporibus cumque."
					},
					exp_level: {
						en: "Junior"
					},
					exp_years: 10,
					location: {
						en: "East Muriel"
					},
					schedule: {
						en: "Full time"
					},
					function: {
						en: "Marketing"
					},
					summary: {
						en:
							"Vel ut occaecati beatae consequuntur possimus. Voluptates qui impedit quia id distinctio quis enim. Nisi eaque nobis tenetur ea laboriosam quia. Quam voluptas debitis totam quasi sequi nesciunt."
					},
					responsibilities: {
						en: [
							"Ratione quo eos ut exercitationem. Et porro aut repellat laudantium iste et exercitationem. Qui et qui eum amet repudiandae eum.",
							"Laborum voluptatum earum quia. Maiores voluptas labore eaque et praesentium. Aut explicabo minima id velit fugiat. Optio eos sit nostrum est optio aliquam nesciunt. Voluptatibus vitae aut eveniet id reiciendis odit aut.",
							"Et commodi harum occaecati qui. Odit quo eum nihil ab unde quia at. Libero dolor eum blanditiis nemo quas autem autem."
						]
					},
					qualifications: {
						en: [
							"Autem impedit excepturi itaque neque ratione molestias. Quas enim illo labore et voluptates corrupti quas ut. Corrupti voluptatum perspiciatis voluptatem molestias minus.",
							"Facere quisquam laudantium animi quo dolorem et. Veritatis aut quidem facere. Nobis corporis sed numquam fugiat illo id. Est exercitationem recusandae consequatur dolores incidunt et.",
							"Repellat soluta ullam alias sint optio. Quo quod sit non quos dolore sed unde ab."
						]
					},
					is_remote: false
				},
				{
					index: 2,
					id: 4,
					title: {
						en: "Nam magnam ut qui maxime ut sed."
					},
					exp_level: {
						en: "Architecture"
					},
					exp_years: 5,
					location: {
						en: "Lake Federicohaven"
					},
					schedule: {
						en: "Per Project"
					},
					function: {
						en: "Web Development"
					},
					summary: {
						en:
							"Culpa aspernatur aut atque saepe omnis officia. Pariatur cupiditate labore et eos sint molestiae id. Quam dicta illum in quo perspiciatis illo."
					},
					responsibilities: {
						en: [
							"Rem quod maxime doloribus eveniet. Aut officia molestias et. Corporis est totam quidem praesentium hic et consequatur earum.",
							"Cumque sed possimus autem assumenda. Est nesciunt excepturi ipsa voluptas veritatis et consequatur consequuntur. Cupiditate et vero aliquid consequuntur autem. Libero doloribus cupiditate et inventore reprehenderit consequatur et.",
							"Ut voluptatem quis placeat maxime minima. Et asperiores dignissimos atque quisquam quis hic quasi. Nihil earum ea eum ut qui suscipit et."
						]
					},
					qualifications: {
						en: [
							"Voluptatem aliquid voluptatem quis voluptatem est in. Quis quas error nam repellendus rerum.",
							"Repellat dolor dolores suscipit blanditiis quae. Placeat explicabo placeat atque et aut perferendis. Saepe ipsum quia expedita tempora fuga esse. Aut ea rerum odit et quia rerum.",
							"Ullam amet in corporis vitae dolor at officia. Rerum voluptates nulla ut autem rerum. Dolorum accusamus quo enim aspernatur tenetur ea omnis."
						]
					},
					is_remote: false
				},
				{
					index: 7,
					id: 5,
					title: {
						en: "Consequatur cum quia nihil recusandae nihil est."
					},
					exp_level: {
						en: "Mid-Level"
					},
					exp_years: 9,
					location: {
						en: "Adamsstad"
					},
					schedule: {
						en: "Part time"
					},
					function: {
						en: "Server Administration"
					},
					summary: {
						en:
							"Nisi consequatur sequi qui et esse et. Voluptatum consequatur autem quo voluptatem amet delectus possimus iste. Est dolorum rerum aut quibusdam dolore."
					},
					responsibilities: {
						en: [
							"Tempora assumenda minus aut voluptatem quo nam. Neque illo deleniti voluptatem natus omnis tempore minus. Corrupti saepe sed et cupiditate aut qui. Illum vel magnam necessitatibus consequatur autem a officia.",
							"Magnam placeat doloremque animi consequatur ipsum. Vel quis aspernatur et voluptatum quibusdam accusamus et. Maxime fugiat omnis ut nihil nostrum aut. Voluptas expedita qui qui ut.",
							"Blanditiis odit natus quia quibusdam sed consequatur error rerum. Sint officia autem minima. Alias aperiam iusto ab culpa eos."
						]
					},
					qualifications: {
						en: [
							"Similique asperiores reiciendis doloremque fuga unde possimus repellendus. Laborum omnis dolore quod est fugit. Tempore dolore occaecati tempore quaerat. Voluptate sed officia non sed consequatur aut qui labore.",
							"Exercitationem officia voluptatem qui labore nihil velit aut. Harum quasi qui consectetur voluptatem. Quos corporis reprehenderit molestias quia necessitatibus qui. Dolores aut dolorem et necessitatibus minima sequi id aspernatur. Quos nihil dolores perferendis.",
							"Iusto ut harum sit. Atque distinctio soluta laudantium qui tempora. Magni et fuga ea officia dolorem. Perferendis expedita fuga a eaque fugit ea et."
						]
					},
					is_remote: false
				},
				{
					index: 10,
					id: 6,
					title: {
						en: "Repudiandae officia corrupti illum dolorem."
					},
					exp_level: {
						en: "Mid-Level"
					},
					exp_years: 6,
					location: {
						en: "Maudieburgh"
					},
					schedule: {
						en: "Per Project"
					},
					function: {
						en: "Marketing"
					},
					summary: {
						en:
							"Natus dicta a eaque voluptate vitae aliquid doloribus. Illo placeat vel quos pariatur atque dignissimos. Nihil rerum quam ipsum aut quo eum itaque dolor. Ut minima error sit incidunt."
					},
					responsibilities: {
						en: [
							"Laboriosam commodi eos quos sit quasi vel eum. Pariatur sunt aut at aut dolorum qui. Hic quaerat corporis est magnam ut. Eos nihil molestiae consectetur omnis assumenda sed delectus totam. Alias et quia beatae dolor quis eum porro.",
							"Nihil quia consequuntur et eum ullam. Hic dignissimos ea sint quasi dolorem.",
							"Ut placeat amet nam porro repudiandae quasi itaque. Dolorem sed nihil aliquid corporis. Enim alias qui illum non harum ratione."
						]
					},
					qualifications: {
						en: [
							"Laborum similique perferendis animi incidunt sequi. Iste sunt qui voluptates.",
							"Fuga et doloribus fugiat aliquid et voluptatem magni. Ex pariatur a aperiam et et voluptatem repellendus. Perferendis cum animi officia voluptatem omnis vitae.",
							"Voluptas molestiae consequatur ipsam qui. Recusandae mollitia eos sit omnis."
						]
					},
					is_remote: false
				},
				{
					index: 2,
					id: 7,
					title: {
						en:
							"Sunt nostrum et reprehenderit explicabo nostrum vel quos suscipit."
					},
					exp_level: {
						en: "Mid-Level"
					},
					exp_years: 2,
					location: {
						en: "Lake Katelin"
					},
					schedule: {
						en: "Full time"
					},
					function: {
						en: "Server Administration"
					},
					summary: {
						en:
							"Aspernatur quis neque in ea. Quia debitis provident vero reiciendis. A soluta voluptatem explicabo facilis. Quis distinctio sit quo."
					},
					responsibilities: {
						en: [
							"Vero quia cumque voluptatem repudiandae earum non tempora. Occaecati voluptatibus optio velit cupiditate aut. Aspernatur velit omnis facilis dolorem sit ducimus.",
							"Inventore voluptatem ipsa in eaque similique aut distinctio. Consequatur veniam voluptatem et molestiae accusamus voluptatem odit. Vero officiis modi itaque at omnis sed ut ea.",
							"Distinctio aut omnis quo praesentium. Ullam ea et dolor. Necessitatibus omnis eos fugit itaque ea non."
						]
					},
					qualifications: {
						en: [
							"Nostrum sit est ut est eos. Et corrupti aut optio voluptas. Nulla est earum voluptatem maiores suscipit. Quod veritatis quia quas dolorum voluptatem commodi.",
							"Reiciendis magni accusantium commodi et qui et. Omnis eum sit est alias consequatur aut consectetur necessitatibus. Nihil natus nihil autem ex tenetur quis voluptatibus.",
							"Velit exercitationem qui omnis dignissimos ipsam ullam rem. Asperiores quod molestiae unde tempore aut tempora suscipit. Cum adipisci neque sit error est veniam quas nulla."
						]
					},
					is_remote: false
				},
				{
					index: 7,
					id: 8,
					title: {
						en: "Quis ut dolores odit ut officiis."
					},
					exp_level: {
						en: "Fresh"
					},
					exp_years: 6,
					location: {
						en: "West Noahbury"
					},
					schedule: {
						en: "Full time"
					},
					function: {
						en: "Web Development"
					},
					summary: {
						en:
							"Sapiente optio velit voluptas quo tempore eos. Laborum placeat quos voluptate quis. Accusantium et rerum iusto illo."
					},
					responsibilities: {
						en: [
							"Eaque et praesentium eum perferendis. Id eaque et expedita vitae sit vero. Est ut tenetur vel. Sit est tenetur incidunt dolores voluptatem molestias iusto.",
							"Inventore ut sint nemo. Sapiente provident et exercitationem quia.",
							"Id explicabo voluptas vitae possimus. Accusantium sit natus quidem qui perferendis ducimus. Ullam impedit non molestiae ipsa illo saepe omnis."
						]
					},
					qualifications: {
						en: [
							"Iure est eos est similique consectetur qui vel amet. Excepturi voluptatem veritatis libero alias. Corrupti eveniet amet soluta qui ducimus incidunt.",
							"Est aut magnam veniam sapiente consectetur. Voluptates incidunt ut ut vel. Vel assumenda eveniet enim velit.",
							"Commodi id incidunt molestias rerum. Occaecati culpa debitis sit quia. Necessitatibus commodi accusamus rerum dolore et quidem natus. Dolore necessitatibus voluptatem quia reprehenderit exercitationem ipsa tempore."
						]
					},
					is_remote: false
				},
				{
					index: 5,
					id: 9,
					title: {
						en: "Necessitatibus eum et mollitia earum sequi quaerat voluptatum."
					},
					exp_level: {
						en: "Fresh"
					},
					exp_years: 8,
					location: {
						en: "East Aryanna"
					},
					schedule: {
						en: "Part time"
					},
					function: {
						en: "Marketing"
					},
					summary: {
						en:
							"Blanditiis excepturi quasi nostrum ut laboriosam quia. Et et nobis natus ab. Reiciendis consequatur amet in nisi et molestias nisi. Corporis saepe nihil tempore amet qui id vitae dolore."
					},
					responsibilities: {
						en: [
							"Iure aspernatur consequatur sit eligendi saepe odit aut. Ab unde incidunt distinctio incidunt. Aliquid magnam placeat qui soluta quo itaque. Magni cupiditate molestiae asperiores laboriosam.",
							"Harum deleniti expedita incidunt excepturi. Autem pariatur id voluptas recusandae. Recusandae nobis accusantium ab et nam occaecati.",
							"Enim iste dolores numquam quod suscipit quia. Omnis soluta officia sed aut culpa iure. Nihil labore facere nihil et optio consequatur. At omnis necessitatibus perferendis possimus eligendi voluptate. Minima quaerat ut officia architecto quasi."
						]
					},
					qualifications: {
						en: [
							"Dolores quis aut et non et fuga et voluptate. Odit enim dolor sed cumque neque eos rerum distinctio. Voluptas inventore non labore. Veniam iusto blanditiis dicta suscipit ratione dolores.",
							"Delectus culpa dicta tempora similique quasi aut impedit voluptate. Voluptatem quod aperiam iusto iure ex est adipisci. Non eaque incidunt iste voluptatum repellat.",
							"Autem et quia eligendi ea ratione eos suscipit. Id excepturi temporibus laboriosam quae. Rem recusandae ea error consequatur vel culpa aspernatur."
						]
					},
					is_remote: true
				},
				{
					index: 4,
					id: 10,
					title: {
						en: "Qui facere voluptatibus magnam similique."
					},
					exp_level: {
						en: "Architecture"
					},
					exp_years: 7,
					location: {
						en: "East Tressie"
					},
					schedule: {
						en: "Full time"
					},
					function: {
						en: "Server Administration"
					},
					summary: {
						en:
							"Nesciunt voluptatem in quas soluta laboriosam. Placeat labore et asperiores rerum corporis aut cum. Quisquam omnis et consequuntur similique. Consectetur ex accusantium consequatur nemo repellat a."
					},
					responsibilities: {
						en: [
							"Qui ab rerum ut aut. Sed eligendi sapiente sunt voluptas facilis eveniet. Possimus itaque cum quis.",
							"Modi magnam autem eligendi libero temporibus. Saepe numquam id molestiae dolor libero. Cumque dolor qui aspernatur quo rem et et.",
							"Debitis officia est veritatis. Ipsa rerum minus ut. Inventore temporibus eveniet molestias iure est."
						]
					},
					qualifications: {
						en: [
							"Fugiat ipsam aut ut quasi. Rerum voluptatem aut ab. Facere minima facere vel voluptate et qui dignissimos repudiandae.",
							"Incidunt rerum doloribus amet provident. Et neque omnis rerum alias. Autem labore modi et eum nostrum et. Maiores id quia sit labore unde labore eaque dolores.",
							"Aut ullam qui eius. Autem consequatur voluptas et rem. Dolorem modi porro vitae numquam. Cumque necessitatibus eveniet aspernatur qui."
						]
					},
					is_remote: true
				}
			]
		};
	}
};
</script>

<style>
.job:nth-child(even) {
	flex-direction: row-reverse;
}
.custom-lines-p {
	line-height: 2rem;
	max-height: 6rem;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden !important;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
}
.vm--modal {
	border-top: solid 30px #ffd801;
	border-radius: 20px;
}
</style>
